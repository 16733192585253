<template>

  <el-dialog
      title="物料收发存汇总表"
      v-model="performanceinfo"
      width="80%"
      height="80%"
      center append-to-body @close='clearinfo'>
    <div class="el-dialog-div">
      <el-form ref="searchForm"   label-width="240" :inline="true">
      	  <el-row>
			  <el-form-item label="时间查询:">
			   <el-date-picker v-model="beginDate" type="month" style="height:35px" value-format="YYYY-MM" ></el-date-picker>
			  </el-form-item>
      		  <el-form-item label="选择项目:" prop="name">
      		    <el-select v-model="article" placeholder="请选择" style="width:200px" clearable @change="getNextType">
      		      <el-option v-for="data in optionstype" :key="data.plot_id" :label="data.plot_name" :value="data.plot_id"></el-option>
      		    </el-select>
      		  </el-form-item>
      		  <el-form-item label="一级分类:" prop="name">
      		    <el-select v-model="typeOne" placeholder="请选择" style="width:200px" @change="getSecType" >
      		      <el-option v-for="data in optionsype" :key="data.id" :label="data.name" :value="data.id"></el-option>
      		    </el-select>
      		  </el-form-item>
      		  <el-form-item label="二级分类:" prop="name">
      		    <el-select v-model="typeTwo" placeholder="请选择" style="width:200px" >
      		      <el-option v-for="data in secType" :key="data.id" :label="data.name" :value="data.id"></el-option>
      		    </el-select>
      		  </el-form-item>
      		  <el-form-item>
      		  	<el-button type="primary" @click="queryMembers()">搜索</el-button>
				<el-button type="primary" @click="exportMaterialReceiveExcel()">导出</el-button>
      		  </el-form-item>
      	  </el-row>
        
      </el-form>
     <el-table :data="dataList" :showSummary="true" :summary-method="getSummariesMethod"  row-key="id" border  ref="multipleTable">
       <el-table-column label="序号" width="50px">
     		<template v-slot="scope">
     			{{ scope.$index+1 }}
     		</template>
     	</el-table-column>
     	<el-table-column prop="number" label="编号" ></el-table-column>
     	<el-table-column prop="plot_name" label="所属项目" ></el-table-column>
        <el-table-column prop="typeName" label="分类" ></el-table-column>
        <el-table-column prop="secName" label="品名"></el-table-column> 
		<el-table-column prop="specifications" label="规格型号"></el-table-column> 
		<el-table-column prop="unit" label="单位"></el-table-column>
     	<el-table-column prop="price" label="价格"></el-table-column>
     	<el-table-column prop="quantity" label="期初数量"></el-table-column>
     	<el-table-column prop="receipt_quantity" label="入库数量"></el-table-column>
		<el-table-column prop="receipt_total_price" label="总价"></el-table-column>
		<el-table-column prop="use_quantity" label="使用数量"></el-table-column>
		<el-table-column prop="use_total_price" label="总价"></el-table-column>
		<el-table-column prop="inventory_quantity" label="库存数量"></el-table-column>
		<el-table-column prop="inventory_total_price" label="总价"></el-table-column>
     	 <el-table-column prop="remark" label="备注"></el-table-column> 
     	<el-table-column prop="datetime" label="时间" value-format="YYYY-MM"></el-table-column>
     </el-table>
    </div>
    <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-size="pagesize"
        layout="total,sizes,prev,pager,next,jmper"
        :total="totalCount">
    </el-pagination>
    <div class="ucForm-group-ad">
      <div class="ucForm-group ucBtn-bg">
        <button class="ucBtn ucBtn-refresh ucBtn-gray" v-on:click="closeDialog" ><i
            class="iImg"></i>返回</button>
      </div>
    </div>

  </el-dialog>

</template>

<script>
export default {
  created () {
    this.performanceinfo = true;
   //this.queryMembers();
   this.searchMaterialTypes();
   this.getplotname()
  },
  data(){
    let self=this;
    return{
      dataList:[],  //表格数据集
      repairsViewDialogVisible:false,
      repairsViewDialogVisible2:false,
      user_name:null,   //综合查询条件参数
      article:null,
      optionstype:[],     //所属项目选项
      optionsype:[],
      secType:[],
      typeOne:null,  //选择的所属项目
      typeTwo:null,
      beginDate:null,
      totalCount:0,          //总页数
      pagesize:10,        //每页数量
      currentPage:1,    //初始页
    }},

  methods: {
	  /** 表格合计行渲染所有数据的和，不是当前页的和 */
	  getSummariesMethod(param) {
		const { columns, data } = param;
		const sums = [];
		columns.forEach((column, index) => {
		  if (index === 0) {
			sums[index] = '合计';
			return;
		  }
		  const values = data.map(item => Number(item[column.property]));
		   if (!values.every(value => isNaN(value)) && (column.property === 'quantity' || column.property ==='receipt_quantity' 
		   || column.property ==='receipt_total_price' || column.property ==='use_quantity' || column.property ==='use_total_price'
		   || column.property ==='inventory_quantity' || column.property ==='inventory_total_price')) {
				sums[index] = values.reduce((prev, curr) => {
					const value = Number(curr);
					if (!isNaN(value)) {
						return prev + curr;
					} else {
						return prev;
					}
				}, 0);
				//sums[index] += ' 元';
			}
		});
		return sums;
	  },
    getplotname:function(){
      var self = this;
      var redata = {};
      redata.cmd = "getProject";
      this.$sknet.postJson(this.$skconf("met.surl"), redata, function(datas){
        this.optionstype=datas.datas.items;
      }, function(data) {
        self.$message(data.reason);
      }, self);
    },
    //查询分类的方法
    searchMaterialTypes:function(){
      var self = this;
      var redata = {};
      redata.cmd = "searchMaterialTypes";
      if(this.article != "" && this.article != undefined){
    	redata.plot_id = this.article;
      }
      this.$sknet.postJson(this.$skconf("met.surl"), redata, function(datas){
    	  self.optionsype = [];
    	  self.optionsype.push({id:0,name:'请选择'});
    	  for(var i=0;i<datas.datas.items.length;i++){
    		  self.optionsype.push(datas.datas.items[i]);
    	  }
      }, function(data) {
        self.$message(data.reason);
      }, self);
    },
    getNextType:function(data){
    	this.searchMaterialTypes();
    },
    getSecType:function(data){
      var self = this;
      var redata = {};
      redata.father_id =data ;
    	self.secType=[];
      if(data==0){
    	  return;
      }
      redata.cmd = "searchSecTypes";
      this.$sknet.postJson(this.$skconf("met.surl"), redata, function(datas){
    	  self.secType.push({id:0,name:'请选择'});
    	  for(var i=0;i<datas.datas.items.length;i++){
             self.secType.push(datas.datas.items[i]);
    	  }
      }, 
      function(data) {
        self.$message(data.reason);
      }, self);
    },
	exportMaterialReceiveExcel:function(){
		if(this.beginDate == "" || this.beginDate == undefined){
			this.$message("请选择时间");
			return false;
		}
		var req = {};
		req.cmd = "exportMaterialReceiveExcel";
		req.type_id =this.typeOne;
		req.secn_id = this.typeTwo;
		req.datetime = this.beginDate;
		req.plot_id =this.article;
		this.$sknet.download(this.$skconf("met.surl"), req)
	},
    //条件查询的方法
    queryMembers:function(){
		if(this.beginDate == "" || this.beginDate == undefined){
			this.$message("请选择时间");
			return false;
		}
        this.dataList = [];
        var req = {};
    	req.type_id =this.typeOne;
    	req.secn_id = this.typeTwo;
    	req.datetime = this.beginDate;
    	req.plot_id =this.article;
        req.cmd = "searchMaterialsReceive";
        req.start=(this.currentPage-1) * this.pagesize;
        req.count=this.pagesize;
        this.$sknet.postJson(this.$skconf("met.surl"), req, function(res) {
          console.log(res);
          this.dataList=res.datas.items;
          this.totalCount=res.datas.totalCount;
    	  self.ids = res.datas.ids;
        }, null, this);
    },

    /*分页代码*/
    handleSizeChange: function (size) {
      this.pagesize = size;
      console.log(this.pagesize)  //每页下拉显示数据
    },
    handleCurrentChange: function(currentPage){
      this.currentPage = currentPage;
      this.handleClickQuery();
      console.log(this.currentPage)  //点击第几页

    },
    closeDialog: function(item){
		 this.performanceinfo = false;
      this.$emit('closerepairsView'); //通知父组件改变。
    },
    clearinfo:function(){
		 this.performanceinfo = false;
	   this.$emit('closerepairsView'); //通知父组件改变。
    },

  },


  mounted(){

  },
  components: {

  }
}
</script>

<style scoped>
.el-dialog-div {
  height: 70vh;
  overflow: auto;
  Margin:5px;
}
</style>